<script setup lang="ts">
import { Field } from "vee-validate"
import Template from "~/navigation/template"
const props = withDefaults(
  defineProps<{
    name: string
    disabled?: boolean
    errorMessage?: string
  }>(),
  {
    disabled: false,
  },
)
</script>

<template>
  <Field
    v-slot="{ field, errorMessage, handleChange, handleBlur }"
    :name="name"
  >
    <BaseInput
      v-bind="$attrs"
      :model-value="field.value"
      :error="props.errorMessage != null ? props.errorMessage : errorMessage"
      :disabled="disabled"
      :id="name"
      rounded="lg"
      @update:model-value="handleChange"
      @blur="handleBlur"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </BaseInput>
  </Field>
</template>

<style scoped></style>
